import { render, staticRenderFns } from "./AccountFunds.html?vue&type=template&id=d7353282&scoped=true&"
import script from "./AccountFunds.ts?vue&type=script&lang=ts&"
export * from "./AccountFunds.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/account/_funds.scss?vue&type=style&index=0&id=d7353282&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7353282",
  null
  
)

export default component.exports