import { Vue, Component, Watch } from 'vue-property-decorator';
import { State as StateClass, Getter } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import { BlockchainStateSlice, State } from '@/store/models';
import AccountMetamask from '@/components/account/dashboard/metamask/AccountMetamask.vue';
import AccountUpgrade from '@/components/account/infobox/AccountUpgrade.vue';
import AccountBalance from '@/components/account/balance/AccountBalance.vue';
import AccountDividends from '@/components/account/dividends/AccountDividends.vue';
import AccountFunds from '@/components/account/funds/AccountFunds.vue';
import AccountInvestments from '@/components/account/investments/AccountInvestments.vue';
import Support from '@/components/common/support/Support.vue';
import { titleTemplate } from '@/helpers/meta/title-template';
import { collections } from '@/vue';
import { Asset } from '@/store/models/asset';
import { Investor } from '@/store/models/user';
import { intercom } from '../../../../whitelabel.config';

@Component({
  components: {
    AccountMetamask,
    AccountUpgrade,
    AccountBalance,
    AccountDividends,
    AccountFunds,
    AccountInvestments,
    Support,
  },
})
export default class AccountDashboard extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.dashboard.title').toString()),
    };
  }

  // Biding collection to the instance
  collections = collections;

  intercom = intercom;

  showUpgradeInfobox = false;
  handleSupport = false;

  @StateClass blockchain!: BlockchainStateSlice;
  @StateClass idin!: State['idin'];
  @StateClass assets!: State['assets'];
  @StateClass user!: State['user'];
  @StateClass identificationRequest!: State['identificationRequest'];

  @Getter getInvestedAssets!: Asset[];
  @Getter getNotInvestedAssets!: Asset[];
  @Getter isInvestor!: boolean;

  async mounted(): Promise<void> {
    this.upgradeInfoboxHandle();
  }

  @Watch('user')
  onInvestorChange(newUser: Investor, oldUser: Investor): void {
    if (!oldUser && newUser) {
      this.upgradeInfoboxHandle();
    }
  }

  @Watch('isInvestor')
  onIdentifiedChange(): void {
    this.upgradeInfoboxHandle();
  }

  get identificationStatus(): string {
    if (this.isInvestor) {
      return 'approved';
    }

    const customIdent = this.identificationRequest;
    if (customIdent) {
      if (customIdent.status === 'initial') {
        return 'pending';
      }
      if (customIdent.status === 'rejected') {
        return 'rejected';
      }
    }

    return 'initial';
  }

  get showInfoBox(): boolean {
    return this.identificationStatus === 'rejected' || this.identificationStatus === 'pending';
  }

  get notFullNotInvestedAssets(): Asset[] {
    return this.getNotInvestedAssets.filter((asset): boolean => !!asset.sharesAvailable);
  }

  get investedAssets(): Asset[] {
    return this.getInvestedAssets;
  }

  upgradeInfoboxHandle(): void {
    const showUpgradeInfobox = localStorage.getItem('showUpgradeInfobox');
    this.showUpgradeInfobox = !this.isInvestor && showUpgradeInfobox ? JSON.parse(showUpgradeInfobox) : !!this.user && !this.isInvestor;
  }
}
