import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { ValidationObserver } from 'vee-validate';
import { State } from '@/store/models';
import Support from '@/components/common/support/Support.vue';
import { titleTemplate } from '@/helpers/meta/title-template';
import { DataChangeRequest, Investor, isInvestor, KYCMethods } from '@/store/models/user';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import { intercom } from '../../../../../whitelabel.config';

@Component({
  components: {
    Support,
    FormInput,
    ValidationObserver,
  },
})
export default class AccountSettingsDetails extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.details.title').toString()),
    };
  }

  intercom = intercom;

  emailPasswordConfirmation = '';
  currentPassword = '';
  newPassword = '';
  confirmPassword = '';
  mailPassword = '';
  bankAccount = '';
  nationalRegisterNumber= '';
  id = '';
  name = '';
  surname = '';
  email = '';

  currentDataChangeRequestType: DataChangeRequest['type'] | '' = '';

  @StateClass auth!: State['auth'];
  @StateClass user!: State['user'];
  @StateClass operations!: State['operations'];
  @StateClass identificationRequest!: State['identificationRequest'];
  @StateClass dataChangeRequests!: State['dataChangeRequests'];

  @Action changeEmail!: Function;
  @Action changePassword!: Function;
  @Action changeNameSurname!: Function;
  @Action logOut!: Function;
  @Action changePersonalDetails!: Function;
  @Action createDataChangeRequest!: Function;
  @Action(addToastMessage) addToastMessage!: Function;
  @Ref('formPassword') readonly formPassword!: InstanceType<typeof ValidationObserver>;

  @Getter isUserLoggedIn!: boolean;
  @Getter isInvestor!: boolean;

  @Watch('operations')
  onOperationsChanged(newOperation: State['operations'], oldOperation: State['operations']): void {
    const ops = [
      'sendEmailVerification', 'changeEmail', 'changePassword',
      'changeNameSurname', 'CHANGE_BANK_ACCOUNT_EMAIL_ERROR', 'CHANGE_BANK_ACCOUNT_EMAIL_SUCCESS',
    ];

    // Displaying error if its a wanted operation, the name is different or the status is different
    if (newOperation && oldOperation && ops.some((opName): boolean => opName === newOperation.name)
      && (newOperation.name !== oldOperation.name || newOperation.status !== oldOperation.status)) {
      if (newOperation.status === 'error') {
        // Display error message
        this.launchToast(newOperation.error!, 'danger');
      } else if (newOperation.status === 'success') {
        // Display success message
        switch (newOperation.name) {
          case 'sendEmailVerification': {
            this.launchToast(this.$t('settings.details.mailChanged') as string, 'success');

            break;
          }
          case 'changePassword': {
            this.newPassword = '';
            this.confirmPassword = '';
            this.mailPassword = '';
            this.$nextTick((): void => {
              this.formPassword.reset();
            });
            this.launchToast(this.$t('settings.details.passwordChanged') as string, 'success');

            break;
          }
          case 'changeNameSurname': {
            this.launchToast(this.$t('settings.details.nameUpdated') as string, 'success');

            break;
          }
          case 'CHANGE_BANK_ACCOUNT_EMAIL_SUCCESS': {
            this.launchToast(this.$t('settings.details.linkSent') as string, 'success');

            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  // Updating email from vuex
  @Watch('auth', { deep: true })
  onNewEmail(newAuth: State['auth']): void {
    this.email = newAuth?.email || this.email;
  }

  // Updating variables from vuex
  @Watch('user', { deep: true })
  onInvestorChange(newUser: State['user']): void {
    this.resetFields(['bankAccount', 'name', 'surname', 'nationalRegisterNumber'], newUser);

    // Bank account is confing for identification request
    const iRBankAccount = this.identificationRequest?.bankAccount;
    if (newUser && !(newUser as Investor).bankAccount && iRBankAccount) {
      this.bankAccount = iRBankAccount;
    }
  }

  mounted(): void {
    this.resetFields(['bankAccount', 'name', 'surname', 'nationalRegisterNumber'], this.user);

    if (this.isUserLoggedIn && this.auth?.email) {
      this.email = this.auth.email;
    }

    // Bank account is confing for identification request
    const iRBankAccount = this.identificationRequest?.bankAccount;
    if (this.user && !(this.user as Investor).bankAccount && iRBankAccount) {
      this.bankAccount = iRBankAccount;
    }
  }

  /**
   * Checks for exisiting data change requests.
   */
  get nameDataChangeRequestPending(): DataChangeRequest | undefined {
    return this.dataChangeRequests?.find(({ type, status }: DataChangeRequest): boolean => type === 'name' && status === 'pending');
  }

  get bankAccountDataChangeRequestPending(): DataChangeRequest | undefined {
    return this.dataChangeRequests?.find(({ type, status }: DataChangeRequest): boolean => type === 'bankAccount' && status === 'pending');
  }
  get registerNumberDataChangeRequestPending(): DataChangeRequest | undefined {
    return this.dataChangeRequests?.find(({ type, status }: DataChangeRequest): boolean => type === 'nationalRegisterNumber' && status === 'pending');
  }

  /**
   * Loading form data.
   */
  get loading(): boolean {
    return (!!this.auth && this.auth.status === 'processing') || (this.user === null);
  }

  get submittingNameSurname(): boolean {
    return this.operations && this.operations.name === 'changeNameSurname' && this.operations.status === 'processing';
  }

  get submittingEmail(): boolean {
    return this.operations && this.operations.name === 'changeEmail' && this.operations.status === 'processing';
  }

  get submittingPassword(): boolean {
    return this.operations && this.operations.name === 'changePassword' && this.operations.status === 'processing';
  }

  get submittingBankAccount(): boolean {
    return this.operations && this.operations.name === 'CHANGE_BANK_ACCOUNT_EMAIL_PROCESSING' && this.operations.status === 'processing';
  }

  get submittingRegisterNumber(): boolean {
    return this.operations && this.operations.name === 'changeRegisterNumber' && this.operations.status === 'processing';
  }

  resetFields(fields: string[], user: State['user']): void {
    if (user) {
      fields.forEach((field: string): void => {
        if (this.user && this[field] !== user[field]) {
          this[field] = user[field] || '';
        }
      });
    }
  }

  showDataChangeRequestModal(type: 'bankAccount' | 'name' | 'nationalRegisterNumber'): void {
    this.$modal.show('dataChangeRequestConfirmModal');
    this.currentDataChangeRequestType = type;
  }

  handleDataChangeRequest(): void {
    if (this.user && isInvestor(this.user)) {
      let previousData: DataChangeRequest['previousData'] | {} = {};
      let newData: DataChangeRequest['newData'] | {} = {};

      if (this.currentDataChangeRequestType === 'name') {
        previousData = {
          ...(this.user.kycMethod !== KYCMethods.Idin && { name: this.user.name }),
          surname: this.user.surname,
        };
        newData = {
          ...(this.user.kycMethod !== KYCMethods.Idin && { name: this.name }),
          surname: this.surname,
        };
      }

      if (this.currentDataChangeRequestType === 'bankAccount') {
        previousData = {
          bankAccount: this.user.bankAccount,
        };
        newData = {
          bankAccount: this.bankAccount,
        };
      }
      // nationalRegisterNumber change request
      if (this.currentDataChangeRequestType === 'nationalRegisterNumber') {
        previousData = {
          nationalRegisterNumber: this.user.nationalRegisterNumber,
        };
        newData = {
          nationalRegisterNumber: this.nationalRegisterNumber,
        };
      }

      const request: DataChangeRequest = {
        type: (this.currentDataChangeRequestType as DataChangeRequest['type']),
        status: 'pending',
        newData: (newData as DataChangeRequest['newData']),
        previousData: (previousData as DataChangeRequest['previousData']),
      };

      this.createDataChangeRequest(request);
    }

    this.resetFields(['bankAccount', 'name', 'surname', 'nationalRegisterNumber'], this.user);
    this.$modal.hide('dataChangeRequestConfirmModal');
  }

  launchToast(text: string, type: 'success' | 'danger'): void {
    this.addToastMessage({
      text,
      type,
    });
  }
}
